/*                                                   */
/*      Please follow latest alignments at docs      */
/*      www3.shoutly.com/docs                        */
/*                                                   */
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Billing } from '../../models/billing.model'
import { Workfield } from '../../models/workfields.model'
import { AdminInOrganizationResponse, OrgAPIResponse, OrgBalanceAPIResponse, Organization, OrganizationPayoutApiRequestV2, OrganizationPayoutApiResponseV2, orgPreview } from '../../models/organization.model'
import { AuthUserWrapper, DepartmentUser, InvitedUser, retrieveInvitedUser, User } from '../../models/user.model'
import { ApiCollaborationResponse, CollabCalculationData, CollaborationItem, CollaborationItemCreateRequest, CollaborationReportResponse, CollaborationReportResponseExtended, CollabSpreadsheetAPIResponse } from '../../models/collaboration.model'
import { userCurrentOrgResponse, UserRole } from '../../models/user-roles.model'
import { Department } from '../../models/departments.model'
import { ShoutlyNotification } from '../../models/notifications.model'
import { PaginatedResponse } from '../../models/paginated.model'
import { ApiRequestBillCreate, ApiResponseBillCreate, Invoice, SelfInvoice, Wallet, WalletResponse } from '../../models/wallet.model'
import { ShoutlyChartsResponse } from '../../models/charts.model'
import { Summary } from '../../models/summary.model'
import { CostCenterResponse } from '../../models/cost-centers.model'
import { ShoutlyEidAddTransactionRequest, ShoutlyEidAddTransactionResponse, ShoutlyEidTransactionResponse, ShoutlyEidSubmitSMSOtpErrorResponse, ShoutlyAuthResponseMessage } from '../../auth/models/auth.model'
import { ShoutlyExpense } from '../../models/expenses.model'
import { ApiRequestInvitedUser } from '../../models/api.model'
import { ToDoTaskAPI } from '../../models/to-do-tasks.model'
import { ApiWiseFieldConfigurationWrapper } from '../../models/settings.model'
import { ApiReportableCollabPeriod } from '../../models/collab-reports.model'
import { UploadFileResponseAPI } from '../../models/file-upload.model'
import { OrganizationBillingApiResponse, OrganizationBillingApiRequest } from '../../models/organization.model'
import { APITokenRefreshResponse } from '../../models/token.model'
import { SalaryCalculationsDetailsExtended, SalaryCalculationsSummary, SpeedUpCollabPayoutConfirmationDataAPIResponse } from '../../models/calculations.model'
import { PartnerItem, PartnerObject } from '../../models/partner.model'
import { Params } from '@angular/router'
import { AgreementCreateRequest, AgreementResponse } from '../../models/agreements.model'

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    @Inject('ENVIRONMENT') private environment: any,
    private http: HttpClient
  ) { }

  /* EID */

  /**
     * Before start authentication using BankID, authentication transaction should be created. This endpoint does exactly that.
     * For authentication in registration flow following body parameters should be added: 'type': 'register', 'user_type': '{employer | gigger}'.
     * Note! Response contains both transaction_id (id) and accessUrl values. accessUrl can be used if Scrive UI for BankID authentication should be implemented. transaction_id (id) can be used for custom UI implementation using /eid/start endpoint.
     * https://testbed-eid.scrive.com/documentation/api/v1/#null%2Fpaths%2F~1transaction~1%7Btransaction_id%7D%2Fget
     *
     * @returns 200 Created Scrive transactions id successfully { accessUrl, id }
     */
  public eidAddWithRefferalAndProvider(body: ShoutlyEidAddTransactionRequest, headers: HttpHeaders): Observable<ShoutlyEidAddTransactionResponse> {
    return this.http.post<ShoutlyEidAddTransactionResponse>(`${this.environment.apiUrl}/v1/eid/add`, body, { headers })
  }  

  /**
     * Starts authentication process. Can be used for custom UI implementation.
     * @returns 200 Started Scrive authentication successfully { status, providerParameters{}, method, providerInfo{}, id, redirectUrl, provider }
     * @returns 403 Wrong BankID transaction response { err_type, err_mess }
     */
  public eidStart(eid: string, body, headers: HttpHeaders): Observable<ShoutlyEidTransactionResponse> {
    return this.http.post<ShoutlyEidTransactionResponse>(`${this.environment.apiUrl}/v1/eid/start/${eid}`, body , { headers })
  }

  /**
     * Get related authentication information using Scrive transaction id
     *
     * @param eid
     *
     * @returns 200 Retrieved successfully { id, status }
     * @returns 404 Transaction id not found { message }
     */
  public eidGet(eid: string, headers: HttpHeaders): Observable<ShoutlyEidTransactionResponse> {
    return this.http.get<ShoutlyEidTransactionResponse>(`${this.environment.apiUrl}/v1/eid/get/${eid}`, { headers })
  }

  /**
     * Submit One-time password received by sms
     * @param eid transaction_id
     * @param body contains the code received by sms
     */
  public eidSubmitOTP(transaction_id: string, body: { code? }, headers: HttpHeaders): Observable<any> {
    return this.http.post<any>(`${this.environment.apiUrl}/v1/eid/submit/${transaction_id}`, body, { headers })
  }

  /**
     * Authenticate user using email and password
     * @param email
     * @param password
     */
  public eidSubmitEmailAuth(eid: string, body, headers: HttpHeaders) {
    return this.http.post<any>(`${this.environment.apiUrl}/v1/eid/password/${eid}`, body, { headers })
  }

  /* AUTH */

  /**
     * User registration using BankID valid transaction
     * @returns 200 Registered successful { token, token_type, expires_in, user{} }
     * @returns 403 Wrong BankID transaction response { err_type, err_mess }
     */
  public registerUser(body, headers: HttpHeaders): Observable<AuthUserWrapper> {
    return this.http.post<AuthUserWrapper>(`${this.environment.apiUrl}/v1/session/register`, body, { headers })
  }

  /**
     * Login using BankID valid transaction
     * @returns 200 Login successful { token, token_type, expires_in, user{} }
     * @returns 403 Wrong BankID transaction response { err_type, err_mess }
     */
  public loginUser(body, headers: HttpHeaders): Observable<AuthUserWrapper> {
    return this.http.post<AuthUserWrapper>(`${this.environment.apiUrl}/v1/session/login`, body, { headers })
  }

  /**
     * Logout a user
     * @returns 200 Logout successful { message }
     * @returns 401 Returns when user is not authenticated { message }
     */
  public logoutUser(): Observable<any> {
    return this.http.post<Object>(`${this.environment.apiUrl}/v1/session/logout`, null)
  }

  /**
     * Refresh JWT access token for a user
     * @returns 200 Refresh successful { token, token_type, expires_in }
     * @returns 401 Returns when user is not authenticated { message }
     */
  public refreshToken(): Observable<APITokenRefreshResponse> {
    return this.http.post<APITokenRefreshResponse>(`${this.environment.apiUrl}/v1/session/refresh`, null)
  }

  /**
     * Reset password for a user
     * @returns 200 Reset successful { message }
     * @returns 422 Validation error { message }
     */
  public resetPassword(email: string, headers: HttpHeaders): Observable<ShoutlyAuthResponseMessage> {
    return this.http.post<ShoutlyAuthResponseMessage>(`${this.environment.apiUrl}/v1/password/reset`, { email }, { headers })
  }

  public resetPasswordWithToken(params, headers: HttpHeaders): Observable<ShoutlyAuthResponseMessage> {
    return this.http.put<ShoutlyAuthResponseMessage>(`${this.environment.apiUrl}/v1/password/reset`, params, { headers })
  }

  /* ORGANIZATIONS */

  /**
     * Get organization object
     * @returns 200 Logout successful { message }
     * @returns 401 Returns ...
     */
  public getOrganization(org_id: number): Observable<OrgAPIResponse> {
    return this.http.get<OrgAPIResponse>(`${this.environment.apiUrl}/v1/org/${org_id}`)
  }

  public getOrgBalance(): Observable<OrgBalanceAPIResponse> {
    return this.http.get<OrgBalanceAPIResponse>(`${this.environment.apiUrl}/v2/org/balance`)
  }

  public getOrgCurrencies(org_id: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.environment.apiUrl}/v1/org/currencies/all/${org_id}`)
  }

  /**
   * Confirm personal number: start transaction
   * @returns 200 success
  */
  public eidOrgSSNStartTransaction(): Observable<ShoutlyEidTransactionResponse> {
    return this.http.post<ShoutlyEidTransactionResponse>(`${this.environment.apiUrl}/v1/org/ssn`, null)
  }

  /**
     * Confirm personal number: check transaction
     * @returns 200 success
    */
  public eidOrgSSNCheckTransaction(transaction_id: string, personal_number: string): Observable<ShoutlyEidTransactionResponse> {
    let params = new HttpParams()
    params = params.append('transaction_id', `${transaction_id}`)
    params = params.append('ssn', `${personal_number}`)

    return this.http.put<ShoutlyEidTransactionResponse>(`${this.environment.apiUrl}/v1/org/ssn`, params)
  }


  /** Payouts V1 */

  /**
     * Update organization object
     * @returns 200 Logout successful { message }
     * @returns 401 Returns ...
     */
  public updateOrganization(org_id, data): Observable<Organization> {
    return this.http.put<Organization>(`${this.environment.apiUrl}/v2/org/${org_id}`, data)
  }

  public getOrgBilling(org_id): Observable<OrganizationBillingApiResponse> {
    return this.http.get<OrganizationBillingApiResponse>(`${this.environment.apiUrl}/v1/org/${org_id}/billing`)
  }

  public updateOrgBilling(org_id: number, data: OrganizationBillingApiRequest): Observable<OrganizationBillingApiResponse> {
    return this.http.put<OrganizationBillingApiResponse>(`${this.environment.apiUrl}/v1/org/${org_id}/billing`, data)
  }

  public getPayoutMethods(currency: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.environment.apiUrl}/v1/data/payout/${currency}`)
  }

  public getPayoutMethodsV2(org_id: string, currency: string): Observable<string[]> {
    // To be added a currency
    return this.http.get<string[]>(`${this.environment.apiUrl}/v2/org/${org_id}/payout/methods`)
  }

  /** Payouts V2 */
  public updateOrgPayoutV2(org_id: number, data: OrganizationPayoutApiRequestV2): Observable<OrganizationPayoutApiResponseV2> {
    return this.http.put<OrganizationPayoutApiResponseV2>(`${this.environment.apiUrl}/v2/org/${org_id}/payout`, data)
  }

  public getOrgPayoutV2(org_id): Observable<OrganizationPayoutApiResponseV2> {
    return this.http.get<OrganizationPayoutApiResponseV2>(`${this.environment.apiUrl}/v2/org/${org_id}/payout`)
  }

  public updateOrganizationAvatar(data: any | null, org_id: number): Observable<HttpEvent<Organization>> {
    return this.http.post<Organization>(`${this.environment.apiUrl}/v1/org/${org_id}/avatar`, data, {
      reportProgress: true,
      observe: 'events'
    })
  }

  /**
     * Get Organization summary
     */
  public getSummary(): Observable<Summary> {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/summary`)
  }

  /**
     * Get the list of all user roles
     * @returns 200 Roles
     */
  public getRoles(): Observable<UserRole[]> {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/roles`)
  }

  /**
     * Show all the users in current organization
     * @returns 200 Retrieved users
     */
  public getOrganizationUsers(params: HttpParams): Observable<PaginatedResponse<DepartmentUser[]>> {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/org/users`, { params })
  }

  /**
     * Show all the admins and owners in current organization, both existing and invited
     * @returns 200 Retrieved users
     */
  public getAdminsOnOrganization(): Observable<AdminInOrganizationResponse[]> {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/org/admins`)
  }

  /**
  * Show the partners list V2
  * @returns 200 Retrieved orgs (presummably)
  */

  // Method to fetch partners organizations with optional pagination
  public getPartnersOrganizations(params: Params): Observable<PaginatedResponse<PartnerItem[]>> {
    const httpParams = new HttpParams({ fromObject: { ...params } })
    return this.http.get<PaginatedResponse<PartnerItem[]>>(`${this.environment.apiUrl}/v1/org`, { params: httpParams })
  }

  // Method to create a new partner organization
  public createPartnersOrg(org: Organization): Observable<Organization> {
    return this.http.post<Organization>(`${this.environment.apiUrl}/v1/org`, org)
  }

  // Method to get a specific partner Organization by ID
  public getPartnerOrg(partnerOrgId: string): Observable<Organization> {
    return this.http.get<Organization>(`${this.environment.apiUrl}/v1/org/${partnerOrgId}`)
  }

  // Method to update a specific partner Organization by ID
  public updatePartnerOrg(partnerOrgId: number, updateData: any): Observable<Organization> {
    return this.http.put<Organization>(`${this.environment.apiUrl}/v1/org/${partnerOrgId}`, updateData)
  }

  // Method to get payout information for a specific partner OrganizationPayoutApiResponseV2 by ID
  public getPartnerOrgPayout(partnerOrgId: number): Observable<OrganizationPayoutApiResponseV2> { // Replace `any` with a more specific type if available
    return this.http.get<OrganizationPayoutApiResponseV2>(`${this.environment.apiUrl}/v2/org/${partnerOrgId}/payout`)
  }

  // Method to update payout information for a specific partner OrganizationPayoutApiRequestV2 by ID
  public updatePartnerOrgPayout(partnerOrgId: number, payoutData: any): Observable<OrganizationPayoutApiResponseV2> { // Replace `any` with a more specific type if available
    return this.http.put<OrganizationPayoutApiResponseV2>(`${this.environment.apiUrl}/v2/org/${partnerOrgId}/payout`, payoutData)
  }

  // Method to get payout information for a specific partner OrganizationBillingApiResponse by ID
  public getPartnerOrgBilling(partnerOrgId: number): Observable<OrganizationBillingApiResponse> { // Replace `any` with a more specific type if available
    return this.http.get<OrganizationBillingApiResponse>(`${this.environment.apiUrl}/v1/org/${partnerOrgId}/billing`)
  }

  // Method to update payout information for a specific partner OrganizationBillingApiResponse by ID
  public updatePartnerOrgBilling(partnerOrgId: number, billingData: any): Observable<OrganizationBillingApiResponse> { // Replace `any` with a more specific type if available
    return this.http.put<OrganizationBillingApiResponse>(`${this.environment.apiUrl}/v1/org/${partnerOrgId}/billing`, billingData)
  }

  /* COLLABORATIONS */
  /**
     *     Get filtered paginated collabs list
     *     @param limit (int) amount collabs per page. Allowed options: 10, 20, 50, 100. Default: 20
     *     @param page (int) Page number to show. Example: 100
     *     @param proposals (bool) Defines if proposals or collabs should be shown. Allowed 0 or 1
     *     @param filter (string) Allowed options: all, pending, active, declined, finished. Default all
     *     @param sort (string) Allowed options: asc, desc. Default asc
     */
  public getAllCollabsV1(params): Observable<PaginatedResponse<ApiCollaborationResponse[]>> {
    return this.http.get<PaginatedResponse<ApiCollaborationResponse[]>>(`${this.environment.apiUrl}/v1/collabs`, { params })
  }

  /**
     *     Get filtered paginated collabs list v2
     *     @param limit (int) amount collabs per page. Allowed options: 10, 20, 50, 100. Default: 20
     *     @param page (int) Page number to show. Example: 100
     *     @param proposals (bool) Defines if proposals or collabs should be shown. Allowed 0 or 1
     *     @param filter (string) Allowed options: all, pending, active, declined, finished. Default all
     *     @param sort (string) Allowed options: asc, desc. Default asc
     */
  public getAllCollabs(params): Observable<PaginatedResponse<ApiCollaborationResponse[]>> {
    return this.http.get<PaginatedResponse<ApiCollaborationResponse[]>>(`${this.environment.apiUrl}/v2/collabs`, { params })
  }

  /**
     * Retrieves a collab data
     * @param id collaboration
     */
  public getCollabV1(id: number): Observable<ApiCollaborationResponse> {
    return this.http.get<ApiCollaborationResponse>(`${this.environment.apiUrl}/v1/collabs/${id}`)
  }

  public getCollab(id: number): Observable<ApiCollaborationResponse> {
    return this.http.get<ApiCollaborationResponse>(`${this.environment.apiUrl}/v2/collabs/${id}`)
  }

  public getReportableCollabs(): Observable<ApiReportableCollabPeriod[]> {
    return this.http.get<ApiReportableCollabPeriod[]>(`${this.environment.apiUrl}/v1/collabs/hourly`)
  }

  /**
    * Create mulltiple new collabs / multiform request
    * @param data {collaborators[], date_start, date_end, upfront, postpaid, currency, deadline ...}
    * @returns 200 Created new collabs successfully { token, token_type, expires_in }
    */
  public createCollabs(data: FormData): Observable<ApiCollaborationResponse[]> {
    return this.http.post<any>(`${this.environment.apiUrl}/v1/collabs`, data)
  }

  /**
     * Accept a collaboration
     * @param id collaboration id
     * @returns 200 Accepted new collab successfully
     */
  public acceptCollab(id: number): Observable<Partial<ApiCollaborationResponse>> {
    return this.http.put<Partial<ApiCollaborationResponse>>(`${this.environment.apiUrl}/v1/collabs/accept/${id}`, null)
  }

  /**
     * Decline a collaboration
     * @param id collaboration id
     * @returns 200 Declined new collab successfully
     */
  public declineCollab(id: number): Observable<Partial<ApiCollaborationResponse>> {
    return this.http.put<Partial<ApiCollaborationResponse>>(`${this.environment.apiUrl}/v1/collabs/decline/${id}`, null)
  }

  /**
     * Finishes a collaboration
     * @param id collaboration id
     * @returns 200 Finished collab successfully
     */
  public finishCollab(id): Observable<Partial<ApiCollaborationResponse>> {
    return this.http.post<Partial<ApiCollaborationResponse>>(`${this.environment.apiUrl}/v1/collabs/finish/${id}`, null)
  }

  /**
     * Decline cancellation request for collab
     * @param id collaboration id
     * @returns 200 Accepted cancellation successfully
     */
  public declineCancelCollab(id): Observable<Partial<ApiCollaborationResponse>> {
    return this.http.delete<Partial<ApiCollaborationResponse>>(`${this.environment.apiUrl}/v1/collabs/cancellation/${id}`)
  }

  /**
     * Accept cancellation request for collab
     * @param id collaboration id
     * @returns 200 Accepted cancellation successfully
     */
  public acceptCancelCollab(id): Observable<Partial<ApiCollaborationResponse>> {
    return this.http.put<Partial<ApiCollaborationResponse>>(`${this.environment.apiUrl}/v1/collabs/cancellation/${id}`, null)
  }

  /**
     * Request cancellation of a collab
     * @param id collaboration id
     * @returns 200 Cancelled collab successfully
     */
  public requestCancelCollab(id): Observable<Partial<ApiCollaborationResponse>> {
    return this.http.post<Partial<ApiCollaborationResponse>>(`${this.environment.apiUrl}/v1/collabs/cancellation/${id}`, null)
  }

  /** extension */

  public requestCollabExtension(id, request): Observable<Partial<ApiCollaborationResponse>> {
    return this.http.post<Partial<ApiCollaborationResponse>>(`${this.environment.apiUrl}/v1/collabs/extension/${id}`, request)
  }

  public acceptCollabExtension(id): Observable<Partial<ApiCollaborationResponse>> {
    return this.http.put<Partial<ApiCollaborationResponse>>(`${this.environment.apiUrl}/v1/collabs/extension/${id}`, null)
  }

  public declineCollabExtension(id): Observable<Partial<ApiCollaborationResponse>> {
    return this.http.delete<Partial<ApiCollaborationResponse>>(`${this.environment.apiUrl}/v1/collabs/extension/${id}`)
  }

  // Get simple collab item
  public getCollabItem(id): Observable<CollaborationItem> {
    return this.http.get<CollaborationItem>(`${this.environment.apiUrl}/v1/collabs/item/${id}`)
  }

  // Accept collab items
  public acceptCollabItem(item_ids: number[]): Observable<CollaborationItem> {
    let params = new HttpParams()
    item_ids.forEach(id => {
      params = params.append('item_ids[]', id.toString())
    })

    return this.http.put<CollaborationItem>(`${this.environment.apiUrl}/v1/collabs/item/accept`, { params })
  }

  // Decline collab items
  public declineCollabItem(item_ids: number[]): Observable<CollaborationItem> {
    let params = new HttpParams()
    item_ids.forEach(id => {
      params = params.append('item_ids[]', id.toString())
    })

    return this.http.delete<CollaborationItem>(`${this.environment.apiUrl}/v1/collabs/item/decline`, { params })
  }

  // Create collab items
  public createCollabItems(data: CollaborationItemCreateRequest): Observable<CollaborationItem> {
    return this.http.post<CollaborationItem>(`${this.environment.apiUrl}/v1/collabs/item`, data)
  }

  // Get all collab items: v1/collabs/item?year=2023
  public getAllCollabItems(params: HttpParams): Observable<PaginatedResponse<CollaborationItem[]>> {
    return this.http.get<PaginatedResponse<CollaborationItem[]>>(`${this.environment.apiUrl}/v1/collabs/item`, { params })
  }


  /** Collab report */

  /**
     * Report creation
     * @param collab_id collaboration for which we submit the report - required
     * @param hours amount of hours to report - required
     * @param comment comment for the report - optional
     * @param year year for which we submit the report - if not provided last month used
     * @param month month for which we submit the report - if not provided last month used
     * @returns 200 Created report successfully
     */
  public createHourlyCollaborationReport(params: FormData): Observable<CollaborationReportResponse> {
    return this.http.post<CollaborationReportResponse>(`${this.environment.apiUrl}/v1/collabs/report`, params)
  }

  /**
     * Report accept
     * When the report is created, the employer is now able to accept it.
     * Ids are validated by employer ownership.
     *
     * Collaboration report is not acceptable when its status is accepted or declined (2 or 3).
     * Collaboration is not reportable when reports status is new or accepted (1 or 2)
     *
     * @param report_ids array of report ids that we want to accept
     * @returns 200 Accepted report successfully
     * @returns 400 Report is already accepted or declined
     */
  public acceptHourlyCollaborationReport(report_ids: string[]): Observable<CollaborationReportResponseExtended> {
    return this.http.put<CollaborationReportResponseExtended>(`${this.environment.apiUrl}/v1/collabs/report/accept`, { report_ids })
  }

  /**
     * Report decline
     * The same as Report Accept action, decline is available only for reports that haven’t been declined or accepted before
     * Ids are validated by employer ownership.
     *
     * @param report_ids array of report ids that we want to decline
     * @returns 200 Declined report successfully
     * @returns 400 Report is already accepted or declined
     **/
  public declineHourlyCollaboration(report_ids: string[]): Observable<CollaborationReportResponseExtended> {
    return this.http.put<CollaborationReportResponseExtended>(`${this.environment.apiUrl}/v1/collabs/report/decline`, { report_ids })
  }

  /**
     * Get single report
     * @param report_id
     * @returns 200 Retrieved report successfully
     */
  public getCollaborationReport(report_id: string): Observable<CollaborationReportResponseExtended> {
    return this.http.get<CollaborationReportResponseExtended>(`${this.environment.apiUrl}/v1/collabs/report/${report_id}`)
  }

  /**
     * Get all reports for a collaboration
     * @param params
     * @returns 200 Retrieved reports successfully
     */
  public getCollaborationReports(params: HttpParams): Observable<PaginatedResponse<CollaborationReportResponseExtended[]>> {
    return this.http.get<PaginatedResponse<CollaborationReportResponseExtended[]>>(`${this.environment.apiUrl}/v1/collabs/report`, { params })
  }

  /**
  * Create collaborations from CSV file
  * @param formData
  * @returns 200 Preview created collaborations successfully
  */
  public createCollabsFromSpreadsheet(formData: FormData): Observable<CollabSpreadsheetAPIResponse | { result: 'success' }> {
    return this.http.post<CollabSpreadsheetAPIResponse>(`${this.environment.apiUrl}/v1/csv/upload`, formData)
  }

  /** EXPENSES */

  /** Get all expenses */
  public getExpenses(params: HttpParams): Observable<PaginatedResponse<ShoutlyExpense[]>> {
    return this.http.get<PaginatedResponse<ShoutlyExpense[]>>(`${this.environment.apiUrl}/v1/expenses`, { params })
  }

  /**
     * Store an expense
     * @returns 200 Expense stored successfully
     * @param expenseRequest
     */
  public storeExpense(formData: FormData): Observable<ShoutlyExpense> {
    return this.http.post<ShoutlyExpense>(`${this.environment.apiUrl}/v1/expenses`, formData)
  }

  /**
     * Get an expense
     * @returns
     */
  public getExpense(expense_id: string): Observable<ShoutlyExpense> {
    return this.http.get<ShoutlyExpense>(`${this.environment.apiUrl}/v1/expenses/${expense_id}`)
  }

  /**
     * Accept expenses
     * @returns
     */
  public acceptExpenses(expense_ids: string[]): Observable<ShoutlyExpense[]> {
    return this.http.put<ShoutlyExpense[]>(`${this.environment.apiUrl}/v1/expenses/accept`, { expense_ids })
  }

  /**
     * Decline expenses
     * @returns
     */
  public declineExpenses(expense_ids: string[]): Observable<ShoutlyExpense[]> {
    return this.http.put<ShoutlyExpense[]>(`${this.environment.apiUrl}/v1/expenses/decline`, { expense_ids })
  }

  /**
     * Delete an expense
     * @returns 200 Expense deleted successfully
     * To delete an expense the following conditions must be met:
     * Current organization must me an employer or a gigger of the collaboration assigned to the expense
     * Current state must be “new” or “declined”
    */
  public deleteExpense(expenseId: string): Observable<any> {
    return this.http.delete<any>(`${this.environment.apiUrl}/v1/expenses/${expenseId}`)
  }

  /* PROFILE */

  /**
     * Send email confirmation to own email
     * @returns 200 Sent email confirmation successfully
     */
  public sendConfirmEmail(): Observable<any> {
    return this.http.post<string>(`${this.environment.apiUrl}/v1/email/confirmation`, null)
  }

  /**
     * Get user profile
     * @returns 200
     */
  public getUserProfile(): Observable<User> {
    return this.http.get<User>(`${this.environment.apiUrl}/v1/user`)
  }

  /**
     * Update user profile with new data
     * @param data formatted partial data
     * @returns 200 Updated user profile successfully
     */
  public updateUserProfile(data): Observable<User> {
    return this.http.put<User>(`${this.environment.apiUrl}/v1/user`, data)
  }

  /**
   * Update user guide tour status
   * @param data boolean
   */
  public updateUserGuideTour(data: { guide_seen: 0 | 1 }): Observable<User> {
    return this.http.put<User>(`${this.environment.apiUrl}/v1/user/guide`, data)
  }

  /**
    * Update user profile with new data
    * variant with post method, that allows to update images reporting progress
    * @param data formatted partial data
    * @returns 200 Updated user profile successfully
    */
  public updateUserProfileFormData(data: FormData): Observable<any> {
    return this.http.post<any>(`${this.environment.apiUrl}/v1/user`, data, {
      reportProgress: true,
      observe: 'events'
    })
  }

  /* PARTNERS */

  /**
     * Send simple invites to partners emails
     * @returns 200 Sent invites successfully
     */
  public sendPartnersInvites(emails: string[]): Observable<any> {
    const data = { emails }
    return this.http.post<string>(`${this.environment.apiUrl}/v1/invites`, data)
  }

  /**
     * Send simple invites to partners emails
     * @returns 200 Sent invites successfully
     */
  public getUserPartners(params: Params): Observable<PaginatedResponse<PartnerObject[]>> {
    return this.http.get<PaginatedResponse<PartnerObject[]>>(`${this.environment.apiUrl}/v1/partners`, { params })
  }

  /**
     * Confirm email using submitted token. Login the user. Delete verification token
     * @returns
     * */
  public verifyEmail(token, headers: HttpHeaders): Observable<AuthUserWrapper> {
    return this.http.put<any>(`${this.environment.apiUrl}/v1/email/verification`, token, { headers })
  }

  /**
     * Send email confirmation to own email
     * @returns
     * */
  public sendVerificationEmail(email): Observable<any> {
    return this.http.post<any>(`${this.environment.apiUrl}/v1/email/verification`, { email })
  }

  /* Billing */

  public getBilling(): Observable<any> {
    return this.http.get<User>(`${this.environment.apiUrl}/v1/user`)
  }

  public updateBilling(data): Observable<any> {
    return this.http.put<Partial<Billing>>(`${this.environment.apiUrl}/v1/user`, data)
  }

  /* DATA */
  public getWorkfieldData(): Observable<Workfield[]> {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/data/workfields`)
  }

  public getFeeSettingsData() {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/data/fees/settings`)
  }

  public getCountriesData() {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/data/countries`)
  }

  /* WALLET */

  /**
     * Get sorted list of wallet transactions
     */
  public getWallet(params: HttpParams): Observable<PaginatedResponse<WalletResponse[]>> {
    return this.http.get<PaginatedResponse<WalletResponse[]>>(`${this.environment.apiUrl}/v1/wallet`, { params })
  }

  public getBill(bill_id): Observable<Invoice> {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/bills/${bill_id}`)
  }

  /**
     * Get list of bills with timeline by collab
    */
  public getBillsByCollab(collab_id, page = 1, limit = 10): Observable<PaginatedResponse<Invoice[]>> {
    let params = new HttpParams()
    params = params.append('limit', limit.toString())
    params = params.append('page', page.toString())

    return this.http.get<any>(`${this.environment.apiUrl}/v1/collabs/${collab_id}/invoices`, { params })
  }

  public getBills(params: HttpParams): Observable<PaginatedResponse<Invoice[]>> {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/bills`, { params })
  }

  /**
     * Generate bill
     * @returns 200 Retrieved a bill successfully
     */
  public generateBill(bills: ApiRequestBillCreate): Observable<ApiResponseBillCreate[]> {
    return this.http.post<any>(`${this.environment.apiUrl}/v1/bills`, bills)
  }

  /**
     * Cancel bill
     */
  public cancelBill(id): Observable<null> {
    return this.http.put<any>(`${this.environment.apiUrl}/v1/bills/cancel/${id}`, null)
  }

  /**
     * Get paginated sorted list of invoices transactions
     */
  public getSelfInvoices(params: HttpParams): Observable<PaginatedResponse<SelfInvoice[]>> {
    return this.http.get<PaginatedResponse<SelfInvoice[]>>(`${this.environment.apiUrl}/v1/selfinvoices`, { params })
  }

  public getSelfInvoicesFromCollab(collab_id: number, limit = 100, page = 1): Observable<PaginatedResponse<SelfInvoice[]>> {
    let params = new HttpParams()
    params = params.append('limit', limit.toString())
    params = params.append('page', page.toString())

    return this.http.get<PaginatedResponse<SelfInvoice[]>>(`${this.environment.apiUrl}/v1/collabs/${collab_id}/selfinvoices`, { params })
  }

  /**
   * Get list of transactions with timeline by collab
   * */
  public getTransactionsFromCollab(collab_id: number, page = 1, limit = 10): Observable<PaginatedResponse<WalletResponse[]>> {
    let params = new HttpParams()
    params = params.append('limit', limit.toString())
    params = params.append('page', page.toString())

    return this.http.get<PaginatedResponse<WalletResponse[]>>(`${this.environment.apiUrl}/v1/collabs/${collab_id}/transactions`, { params })
  }

  /** Attest collaboration
     *  and Accept monthly collaboration
     *
     * This action creates new transaction for the collaboration
     * */
  public acceptCollabMonthly(collab_id: number, params: HttpParams): Observable<any> {
    return this.http.put<any>(`${this.environment.apiUrl}/v1/collabs/monthly/accept/${collab_id}`, params)
  }


  /**
   * Collab Calculations
   */

  /** Calc regular collaboration */
  public postCollabCalculations(collabData): Observable<CollabCalculationData> {
    return this.http.post<any>(`${this.environment.apiUrl}/v2/collabs/calculate`, collabData)
  }

  public getCollabCalculationsV2(collab_id: number) {
    return this.http.get<any>(`${this.environment.apiUrl}/v2/collabs/${collab_id}/calculations`)
  }

  // Accepts multiple collab_ids -
  // returns array for multiple collab
  public calculateEarlyPayoutCollab(params: HttpParams): Observable<SalaryCalculationsDetailsExtended> {
    return this.http.get<SalaryCalculationsDetailsExtended>(`${this.environment.apiUrl}/v2/collabs/calculate`, { params })
  }

  public calculateExistingCollab(collab_id: number, params = null): Observable<SalaryCalculationsDetailsExtended> {
    return this.http.get<SalaryCalculationsDetailsExtended>(`${this.environment.apiUrl}/v2/collabs/calculate/${collab_id}`, { params })
  }

  public getSummaryBasedInCollab(): Observable<SalaryCalculationsSummary> {
    return this.http.get<SalaryCalculationsSummary>(`${this.environment.apiUrl}/v1/collabs/summary`)
  }

  public speedUpCollabPayout(collab_id: number): Observable<SpeedUpCollabPayoutConfirmationDataAPIResponse> {
    return this.http.post<SpeedUpCollabPayoutConfirmationDataAPIResponse>(`${this.environment.apiUrl}/v1/collabs/payout/${collab_id}`, null)
  }


  /* DEPARTMENTS */

  /**
     * Show all departments with a list of users
     * @returns 200 {department[]}
     */
  public getDepartments(params: HttpParams): Observable<PaginatedResponse<Department[]>> {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/departments`, { params })
  }

  /**
     * Save new Department
     * @returns 200 success {id, org_id, name}
     */
  public postDepartment(params: HttpParams): Observable<Department> {
    return this.http.post<any>(`${this.environment.apiUrl}/v1/departments`, null, { params })
  }

  /**
     * Show a single department with a lists of its users
     * @returns 200 success {id, org_id, name, created_at, updated_at, users[]}
     */
  public getDepartment(id) {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/departments/${id}`)
  }

  /**
     * Show a single department with a lists of its users
     * @returns 200 success { name, org_id , updated_at, created_at, id}
     */
  public updateDepartment(id: number, params: HttpParams): Observable<Department> {
    return this.http.put<any>(`${this.environment.apiUrl}/v1/departments/${id}`, params)
  }

  /**
     * Delete department
     * @returns 200 success
     */
  public deleteDepartment(id): Observable<string> {
    return this.http.delete<string>(`${this.environment.apiUrl}/v1/departments/${id}`)
  }

  /**
     * Get department invitations
     * @param invite_token
     * @returns 200
     */
  public getDepartmentInvitation(invite_token: string, headers: HttpHeaders): Observable<retrieveInvitedUser> {
    return this.http.get<retrieveInvitedUser>(`${this.environment.apiUrl}/v1/invite/${invite_token}`, { headers })
  }
  /**
     * Get all pending invitations, sent by current organization
     * 200 Retrieve invitations
     */
  public getInvitedUsersIntoCurrentOrganization(params: HttpParams): Observable<PaginatedResponse<InvitedUser[]>> {
    return this.http.get<PaginatedResponse<InvitedUser[]>>(`${this.environment.apiUrl}/v1/user/invite`, { params })
  }

  /**
     * Invite existing or non-existing user to department
     * @return 200
     */
  public inviteUserToDepartment(data: DepartmentUser) {
    return this.http.post<DepartmentUser>(`${this.environment.apiUrl}/v1/user/invite`, data)
  }

  /**
     * Update invitation for user into department
     * @param data { departments[], role, first_name, last_name, position }
     * @param token string
     * @returns 200
     */
  public updateUserInvitedToDepartment(data: ApiRequestInvitedUser, token: string): Observable<InvitedUser> {
    return this.http.put<InvitedUser>(`${this.environment.apiUrl}/v1/user/invite/${token}`, data)
  }

  /**
     * Delete a previously made invitation for an user into department
     * @param token string
     * @returns 200 - Invitation deleted
     */
  public deleteUserInvitedToDepartment(token: string): Observable<any> {
    return this.http.delete<any>(`${this.environment.apiUrl}/v1/user/invite/${token}`)
  }

  /**
     * Accept an invitation to be part of a department as an User
     * @param token
     * @returns 200
     */
  public acceptInvitationToBeUserOnDepartment(token: string): Observable<any> {
    return this.http.put<any>(`${this.environment.apiUrl}/v1/user/invite/status/${token}`, null)
  }

  /**
     * Decline an invitation to be part of a department as an User
     * @param token
     * @returns 200
     */
  public declineInvitationToBeUserOnDepartment(token: string): Observable<string> {
    return this.http.delete<any>(`${this.environment.apiUrl}/v1/user/invite/status/${token}`)
  }

  /* User Departments */

  /**
     * Store new user - department relation
     * @returns 200 success {department_id, user_id, org_id, role, created_at, updated_at }
     */
  // public postUserDepartment (user_id, department_id) {
  //   let params = new HttpParams()
  //   params = params.append('user_id', `${user_id}`)
  //   params = params.append('department_id', `${department_id}`)

  //   return this.http.post<any>(`${this.environment.apiUrl}/v1/user/department`, { params })
  // }

  /**
     * Remove new user
     * @returns 200 success User - department relation removed
     */
  // public deleteUserDepartment (user_id, department_id) {
  //   let params = new HttpParams()
  //   params = params.append('user_id', `${user_id}`)
  //   params = params.append('department_id', `${department_id}`)

  //   return this.http.delete<string>(`${this.environment.apiUrl}/v1/user/department`, { params })
  // }

  /**
   * Edit user-department relation
   * @param HttpParams
   * @returns 200 success
   */
  public updateUserDepartment(params: HttpParams): Observable<any> {
    return this.http.put<any>(`${this.environment.apiUrl}/v1/user/department`, params)
  }

  /**
     * Confirm personal number: start transaction
     * @returns 200 success
    */
  public eidUserSSNStartTransaction(): Observable<ShoutlyEidTransactionResponse> {
    return this.http.post<any>(`${this.environment.apiUrl}/v1/user/ssn`, null)
  }

  /**
     * Confirm personal number: check transaction
     * @returns 200 success
    */
  public eidUserSSNCheckTransaction(transaction_id: string, personal_number: string): Observable<ShoutlyEidTransactionResponse> {
    let params = new HttpParams()
    params = params.append('transaction_id', `${transaction_id}`)
    params = params.append('ssn', `${personal_number}`)

    return this.http.put<any>(`${this.environment.apiUrl}/v1/user/ssn`, params)
  }

  /**
     * Confirm mobile number: start transaction
     * @returns 200 success
    */
  public eidMobileStartTransaction(mobile: string): Observable<ShoutlyEidTransactionResponse> {
    let params = new HttpParams()
    params = params.append('mobile', `${mobile}`)

    return this.http.post<any>(`${this.environment.apiUrl}/v1/user/mobile`, params)
  }

  /**
     * Confirm mobile number: check transaction
     * @returns 200 success
    */
  public eidMobileCheckTransaction(transaction_id: string, code: string): Observable<ShoutlyEidSubmitSMSOtpErrorResponse | ShoutlyEidTransactionResponse> {
    let params = new HttpParams()
    params = params.append('transaction_id', `${transaction_id}`)
    params = params.append('code', `${code}`)

    return this.http.put<any>(`${this.environment.apiUrl}/v1/user/mobile`, params)
  }

  /* User Organizations */

  /**
     * Change other user information (For Admin roles)
     * @param user_id
     * @param params
     * @returns 200
     */
  public updateUserRoleAndPositionForUser(id, params: HttpParams): Observable<void> {
    return this.http.put<any>(`${this.environment.apiUrl}/v1/roles/${id}`, params)
  }

  public deleteRole(id: number): Observable<any> {
    return this.http.delete(`${this.environment.apiUrl}/v1/roles/${id}`)
  }

  /**
     * Show organizations available to the user
     * @returns 200 success Organization[]
     */
  public getOrganizationsForUser(): Observable<Organization[]> {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/org_member_in`)
  }

  /**
     * Search for partners organizations by name and email
     * @returns 200 success
     */
  public getOrganizationPartners(keyword, type): Observable<orgPreview[]> {
    let params = new HttpParams()
    params = params.append('keyword', keyword)
    params = params.append('type', type)

    return this.http.get<any>(`${this.environment.apiUrl}/v1/org/search`, { params })
  }

  /* User current organization */

  /**
     * Validate if current user belongs to given organization and change the current_org flag
     * @returns 200 success: Flag has been changed successfully
     * @returns 403 success: Unauthorized - user doesn't belong to the organization
     */
  public changeCurrentOrgFlag(org_id): Observable<userCurrentOrgResponse> {
    return this.http.put<any>(`${this.environment.apiUrl}/v1/user/current_org/${org_id}`, null)
  }

  /* Notifications */

  /**
     * Get list of user notifications
     * @returns 200 success
     */
  public getAllNotifications(): Observable<ShoutlyNotification[]> {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/notifications`)
  }

  /**
     * Get single notification and mark as read
     * @param uuid id for notification
     * @returns 200 success
     */

  public getNotification(uuid): Observable<ShoutlyNotification> {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/notifications/${uuid}`)
  }

  /* Reports */

  /**
     * Show chosen reports
     * @param period Period in months min 3, max 12
     * @param reason Reason for assignment. If not provided, related reports will be null
     * @param department Department. Allows to limit reporting to selected department
     * @param reports Array of reports to display
     * @returns
     */
  public getReports(period: number, reports: string[], reason?: string, department?: number, currency?: string): Observable<ShoutlyChartsResponse> {
    let params = new HttpParams()

    params = params.append('period', period?.toString())
    params = params.append('currency', currency?.toString())

    reports.forEach(report => {
      params = params.append('reports[]', report)
    })

    /* optionals */
    if (reason) params = params.append('reason', reason)
    if (department) params = params.append('department', department?.toString())

    return this.http.get<any>(`${this.environment.apiUrl}/v1/reports`, { params })
  }

  /*
    Access Wise endpoints where {requested_url} is a Transferwise API request without domain eg.
    https://api.transferwise.com/v1/validators/iban?iban=EE867700771000187087
    needs to be requested
    /v1/wise/v1/validators/iban?iban=EE867700771000187087
  */
  public getWiseFields(wiseRequest): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/v1/wise/v1/${wiseRequest}`)
  }

  /*
    Access Wise endpoints where {requested_url} is a Transferwise API request without domain eg.
    https://api.transferwise.com/v1/validators/iban?iban=EE867700771000187087
    needs to be requested
    /v1/wise/v1/validators/iban?iban=EE867700771000187087
  */
  public getWiseRequirements(params: HttpParams): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/v1/wise/requirements`, { params })
  }

  /**
    * Pass currency code
    * @param currency - Example : USD
    * @param country - Example: AR
    * @returns 200 - Retrieved required fields
    */

  public getRequiredFieldsForPayouts(params: HttpParams): Observable<ApiWiseFieldConfigurationWrapper> {
    return this.http.get<any>(`${this.environment.apiUrl}/v1/wise/fields`, { params })
  }

  /** Cost center / Costs centers */

  /**
    * Show all cost_centers
    * @returns 200 - Retrieved Retrieved cost center successfully
    */
  public getCostCenters(): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/v1/cost_centers`)
  }

  /**
    * Show a single cost center
    * @returns 200 - Retrieved cost center successfully
    */
  public getCostCenter(id): Observable<any> {
    return this.http.get(`${this.environment.apiUrl}/v1/cost_centers/${id}`)
  }

  /**
     * Store cost center
     * @param name Name of the cost center
     * @returns Retrieved cost center successfully
     */
  public postCostCenter(name: string): Observable<CostCenterResponse> {
    return this.http.post<any>(`${this.environment.apiUrl}/v1/cost_centers`, { name })
  }

  /**
    * Edit and update cost center
    * @param id: which cost_center
    * @param name: new string
    * @returns 200 - Edited cost center successfully
    */
  public updateCostCenter(id, name: string): Observable<any> {
    return this.http.put<any>(`${this.environment.apiUrl}/v1/cost_centers/${id}`, { name })
  }

  /**
    * Edit and update cost center
    * @param id: which cost_center
    * @returns 200 - Edited cost center successfully
    */
  public deleteCostCenter(id): Observable<any> {
    return this.http.delete<string>(`${this.environment.apiUrl}/v1/cost_centers/${id}`)
  }

  /** Get agreement text based on language */
  public getAgreementTemplate(name): Observable<string> {
    return this.http.get(`assets/agreements/${name}.html`, { responseType: 'text' })
  }

  /** Get agreements signature from current org and user */
  public getAgreements(params: HttpParams): Observable<AgreementResponse[]> {
    return this.http.get<AgreementResponse[]>(`${this.environment.apiUrl}/v1/agreements`, { params })
  }

  /** Post an agreement signature from current org and user */
  public postAgreement(req: AgreementCreateRequest): Observable<AgreementResponse> {
    return this.http.post<AgreementResponse>(`${this.environment.apiUrl}/v1/agreements`, { ...req })
  }

  /** Get safe currencies to use */
  public getSafeCurrencies(): Observable<string[]> {
    return this.http.get<string[]>(`${this.environment.apiUrl}/v1/data/currencies`)
  }

  /**
   * Get todo tasks for current org
   * @returns 200 - Retrieved todo tasks successfully
   */
  public getToDoTasks(params: HttpParams): Observable<PaginatedResponse<ToDoTaskAPI[]>> {
    return this.http.get<PaginatedResponse<ToDoTaskAPI[]>>(`${this.environment.apiUrl}/v1/todo`, { params })
  }

  /** FILE UPLOAD */

  /**
   * Upload file to server
   */
  public uploadFile(formData: FormData): Observable<HttpEvent<UploadFileResponseAPI>> {
    const req = new HttpRequest('POST', `${this.environment.apiUrl}/v1/files`, formData, {
      reportProgress: true,
      responseType: 'json'
    })

    return this.http.request<UploadFileResponseAPI>(req)
  }

  /**
   * Delete a file from server
   */
  public deleteFile(file_id: number): Observable<UploadFileResponseAPI> {
    return this.http.delete<UploadFileResponseAPI>(`${this.environment.apiUrl}/v1/files/${file_id}`)
  }

  /**
   * External providers integrations
   */
  public pushFortnoxAuthCode(code: string, redirect_uri: string): Observable<any> {
    const headers = new HttpHeaders().set('fx_redirect_uri', redirect_uri)
    const body = { code: code }
    const options = { headers: headers }
    return this.http.post<any>(`${this.environment.apiUrl}/v1/fortnox/activate`, body, options)
  }

}
